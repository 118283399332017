import { template } from "@ember/template-compiler";
import { or } from "truth-helpers";
const GroupsInfo = template(`
  <span class="group-info-details">
    <span class="groups-info-name">
      {{or @group.full_name @group.displayName}}
    </span>
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupsInfo;
